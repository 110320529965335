import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Heading from "../ui/Heading";
import Slider from "react-slick";
import { Link } from "react-router-dom"
import LegacyOne from '../Assets/img/legacy_one.webp'
import LegacyTwo from '../Assets/img/legacy_two.webp'
import LegacyThree from '../Assets/img/legacy_three.webp'
export default function OurLeagcy() {


    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [

            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
    };


    return (
        <section className="section-space">
            <Container fluid className="home-showcase-container our_legacy_section">
                <Heading>
                    <h2> Personalize your jewelry: craftsmanship and exclusive design  </h2>
                    <p>  Trusted Craftsmanship Since 1958 </p>
                </Heading>
                <div className="legacy_container">
                    <Slider {...settings}>
                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/certified-diamonds.jpg')}  loading="lazy" alt="Legacy one " />
                            </div>
                            <div className="text-box">
                                <h2>Certified Diamonds </h2>
                                <p>Manuel Spinosa jewelry located
                                    in Marbella, is the only center
                                    specialized in Diamonds on the
                                    entire Costa del Sol and the
                                    province of Malaga..</p>
                                <Link to="/custom-design"> Learn More </Link>
                            </div>
                        </div>

                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/jewelry-insurance.jpg')}  loading="lazy" alt="Legacy one " />
                            </div>
                            <div className="text-box">
                                <h2> Jewelry Insurance  </h2>
                                <p> Our obsession is perfection and
                                    the highest quality in each piece of
                                    jewelry, which is why we carry out the
                                    entire process ourselves, from the
                                    design to...</p>
                                <Link to="/custom-design"> Learn More </Link>
                            </div>
                        </div>

                        <div className="box">
                            <div className="img-box">
                                <img src={require('../Assets/img/event-proposal.jpg')}  loading="lazy" alt="Legacy one image " />
                            </div>
                            <div className="text-box">
                                <h2> Events Proposal </h2>
                                <p>Each piece is expertly crafted with
                                    precision, showcasing the future of
                                    fine jewelry. From intricate details
                                    to flawless finishes, see your dream
                                    design come to life in stunning 3D</p>
                                <Link to="/custom-design"> Learn More </Link>
                            </div>
                        </div>
                    </Slider>
                </div>

            </Container>
        </section>
    )
}
