import { BrowserRouter, Route, Switch, Redirect, useHistory } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/Assets/css/globle.css";
import "./Containers/Front/showcase_2.o/Assets/global.css";
import "./App.css";
import "../src/Assets/css/custome.css";
import "../src/Assets/css/color.css";
import "../src/Assets/css/Education/style.css";
import "../src/Assets/css/productlist.css";
import "../src/Assets/css/productDetails.css";
import "../src/Assets/css/cart.css";
import "../src/Assets/css/ringsettings.css";
import "../src/Assets/css/mjstatic.css";
import "react-phone-input-2/lib/style.css";
import Home from "./Containers/Front/showcase_2.o/Home";
import React, { useEffect } from "react";
import { Suspense } from "react";
import { Skeleton } from "@mui/material";
import Login from "./Components/Login";
import { useSelector } from "react-redux";
import { isDesktop } from "react-device-detect";
import MainRequest from "./Components/mainRequest";
import MegaMenu from "./Containers/Front/showcase_2.o/Header/MegaMenu";
import { ToastContainer } from "react-toastify";
import CookiesComponent from "./Containers/CookiesComponent";
import CookiesComponent1 from "./Containers/CookiesComponent1";
import RedsysPayementFailed from "./Containers/Pages/Static/RedsysPayementFailed";
import RedsysMiddelRoute from "./Containers/Pages/Static/RedsysMiddelRoute";
import Redirection from "./Redirection";

const Footer = React.lazy(() => import("./Containers/Footer/footer"));
const Myaccount = React.lazy(() => import("./Containers/Pages/Myaccount"));
const Register = React.lazy(() => import("./Containers/Pages/Register"));
const Productdetails = React.lazy(() =>
  import("./Containers/Shopping/productDetails")
);
const Invoice = React.lazy(() => import("./Containers/Pages/invoice"));
const Cart = React.lazy(() => import("./Containers/Pages/cart"));
const Checkout = React.lazy(() => import("./Containers/Pages/checkout"));
const customDesigns = React.lazy(() =>
  import("./Containers/Pages/Static/customDesigns")
);
const Financing = React.lazy(() =>
  import("./Containers/Pages/Static/financing")
);
const MakeAnAppointment = React.lazy(() =>
  import("./Containers/Pages/make-an-appointment")
);
const Testimonials = React.lazy(() =>
  import("./Containers/Pages/Static/testimonials")
);
const Contact = React.lazy(() => import("./Containers/Pages/Static/contact"));
const ReturnsExchanges = React.lazy(() =>
  import("./Containers/Pages/Static/returns-and-exchanges")
);
const Cookies = React.lazy(() => import("./Containers/Pages/Static/Cookies"));

const PrivacyPolicy = React.lazy(() =>
  import("./Containers/Pages/Static/privacy-policy")
);
const TermsConditions = React.lazy(() =>
  import("./Containers/Pages/Static/terms-and-conditions")
);
const TermsAndConditionsRedsys = React.lazy(() =>
  import("./Containers/Pages/Static/termsandconditions_redsys")
);
const OurStory = React.lazy(() =>
  import("./Containers/Pages/Static/our-story")
);
const Blog = React.lazy(() => import("./Containers/Pages/Static/blog"));
const Ordersuccess = React.lazy(() =>
  import("./Containers/Pages/Static/ordersuccess")
);
const NoPage = React.lazy(() => import("./Containers/Pages/Static/NoPage"));
const Wishlist = React.lazy(() => import("./Containers/Pages/wishlist"));
const blogDetails = React.lazy(() =>
  import("./Containers/Pages/Static/blogDetails")
);
const RingSettings = React.lazy(() =>
  import("./Containers/Shopping/ringsettings")
);
const RingSettingsDiamonds = React.lazy(() =>
  import("./Containers/Shopping/ringsettings-diamonds")
);
const RingSettingsDiamondsDetails = React.lazy(() =>
  import("./Containers/Shopping/ringsettings-diamonds-details")
);
const Gemstones = React.lazy(() => import("./Containers/Shopping/gemstone"));
const CompleteRing = React.lazy(() =>
  import("./Containers/Shopping/complete-ring")
);
const GemstonesDetails = React.lazy(() =>
  import("./Containers/Shopping/gemstone-details")
);
const FancyColorDiamond = React.lazy(() =>
  import("./Containers/Shopping/FancyColorDiamond")
);
const Fancycolordetail = React.lazy(() =>
  import("./Containers/Shopping/fancy-detail")
);
const FAQ = React.lazy(() => import("./Containers/Pages/Static/faq"));
const Productlist = React.lazy(() =>
  import("./Containers/Shopping/productlist")
);
const Home1 = React.lazy(() => import("./Containers/Front/showcase_2.o/Home"));
const Education = React.lazy(() => import("./Containers/Education/education"));
const GemstoneEducation = React.lazy(() =>
  import("./Containers/Education/gemstone")
);
const EngagementRingEducation = React.lazy(() =>
  import("./Containers/Education/engagement-ring")
);
const WeddingRingEducation = React.lazy(() =>
  import("./Containers/Education/wedding-ring")
);
const FineJewelryEducation = React.lazy(() =>
  import("./Containers/Education/fine-jewelry")
);
const LooseDiamondEducation = React.lazy(() =>
  import("./Containers/Education/loose-diamonds")
);
const Watches = React.lazy(() => import("./Containers/Shopping/Watches"));
const WatchDetails = React.lazy(() =>
  import("./Containers/Shopping/WatchDetails")
);
const StudSettings = React.lazy(() =>
  import("./Containers/Shopping/Studbilder/stud-settings")
);
const StudDiamonds = React.lazy(() =>
  import("./Containers/Shopping/Studbilder/stud-diamonds")
);
const Studdetails = React.lazy(() =>
  import("./Containers/Shopping/Studbilder/Studdetails")
);
const StudSettingDiamondsDetails = React.lazy(() =>
  import("./Containers/Shopping/Studbilder/StudSettingDiamondDetails")
);
const CompleteStud = React.lazy(() =>
  import("./Containers/Shopping/Studbilder/complete-stud")
);
const BandBuilder = React.lazy(() =>
  import("./Containers/Shopping/BandBuilder")
);

const Payment = React.lazy(() => import("./Containers/Pages/Static/Payment"));
const App = () => {
  const history = useHistory()

  const login = useSelector((state) => state.persistedReducer.home.logindrawer);
  const user = useSelector((state) => state.persistedReducer.home.userData);
  return (
    <>
    <Redirection/>
      <BrowserRouter>
        <MainRequest />
        {/* <Header menudata={menudata}  currencydata={currencydata} />   */}
        <MegaMenu />
        <div className="mainPage">
          <Switch>
            <Route exact path="/" component={Home} />
            <Suspense
              fallback={
                <>
                  <Skelton />
                </>
              }
            >
              {user?.isLogin ? (
                <Switch>
                  <Route path="/account/:value" component={Myaccount} />
                  <Route path="/invoice/:order_id" component={Invoice} />
                  <Route
                    path="/engagement-rings"
                    component={RingSettings}
                    exact
                  />
                  <Route
                    path="/engagement-rings/:stone"
                    component={RingSettings}
                    exact
                  />
                  <Route
                    path="/ringsettingdetail/:id/:slug"
                    component={Productdetails}
                    exact
                  />
                  <Route path="/band-builder" component={BandBuilder} exact />
                  <Route path="/gemstones" component={Gemstones} exact />
                  <Route
                    path="/gemstones/:subcategory"
                    component={Gemstones}
                    exact
                  />
                  <Route
                    path="/fancy-color-diamond"
                    component={FancyColorDiamond}
                    exact
                  />
                  <Route
                    path="/fancy-color-diamond-detail/:stock_no"
                    component={Fancycolordetail}
                  />
                  <Route
                    exact
                    path="/diamonds"
                    component={RingSettingsDiamonds}
                  />
                  <Route
                    exact
                    path="/diamonds/lab/:subcategory"
                    component={RingSettingsDiamonds}
                  />
                  <Route
                    exact
                    path="/diamonds/:subcategory"
                    component={RingSettingsDiamonds}
                  />
                  <Route path="/watches" component={Watches} exact />
                  <Route path="/watches/:brand" component={Watches} exact />
                  <Route
                    path="/diamonds-details/:stock_no"
                    component={RingSettingsDiamondsDetails}
                  />
                  <Route
                    path="/gemstone-details/:stock_no"
                    component={GemstonesDetails}
                  />
                  <Route
                    path="/watch-details/:stock_no"
                    component={WatchDetails}
                  />
                  <Route
                    path="/complete-diamond-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route
                    path="/complete-gemstone-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route
                    path="/complete-fancycolor-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route path="/search/:search_text" component={Productlist} />
                  <Route path="/cart" component={Cart} exact />
                  <Route path="/checkout-shipping" component={Checkout} exact />
                  <Route
                    path="/custom-design"
                    component={customDesigns}
                    exact
                  />
                  <Route path="/financing" component={Financing} exact />
                  <Route
                    path="/make-an-appointment"
                    component={MakeAnAppointment}
                    exact
                  />
                  <Route path="/testimonials" component={Testimonials} exact />
                  <Route
                    path="/terms-and-conditions"
                    component={TermsConditions}
                    exact
                  />
                  <Route
                    path="/terms-conditions-redsys"
                    component={TermsAndConditionsRedsys}
                    exact
                  />
                  <Route
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                    exact
                  />
                  <Route
                    path="/returns-and-exchanges"
                    component={ReturnsExchanges}
                    exact
                  />
                  <Route path="/cookies" component={Cookies} exact />
                  <Route path="/payment" component={Payment} exact />
                  <Route path="/contact" component={Contact} exact />
                  {/* <Route path="/2021-gift-guide" component={GiftGuide} /> */}
                  <Route path="/about" component={OurStory} exact />
                  <Route path="/blog" component={Blog} exact />
                  <Route path="/faq" component={FAQ} exact />
                  <Route path="/blog-details/:url" component={blogDetails} />
                  <Route
                    path="/wishlist"
                    render={() => <Redirect to="/account/wishlist" />}
                    exact
                  />
                  <Route
                    path="/"
                    render={() => <Redirect to="/our-story?srsltid=AfmBOopvFJc4J5XxHaJd_230nXiaTwSX1glxFmXCoJR1In6ZJTxFbtzB" />}
                    exact
                  />
                  <Route
                    path="/productdetail/:slug"
                    component={Productdetails}
                    exact
                  />
                  <Route path="/success" component={Ordersuccess} exact />
                  <Route
                    path="/failure"
                    component={RedsysPayementFailed}
                    exact
                  />
                  <Route
                    path="/redsyspaymentproccess"
                    component={RedsysMiddelRoute}
                    exact
                  />
                  <Route
                    path="/ordersuccess/:order_id/:order_no/:txn_id"
                    component={Ordersuccess}
                    exact
                  />
                  {/* <Route
                    path="/jewelry/:listcategory/:subcategory"
                    component={Productlist}
                    exact
                  />
                  <Route
                    path="/jewelry/:listcategory"
                    component={Productlist}
                    exact
                  /> */}

                  <Route path="/fine-jewelry" component={Productlist} exact />
                  <Route path="/wedding-rings" component={Productlist} exact />
                  <Route
                    path="/fine-jewelry/:subcategory"
                    component={Productlist}
                    exact
                  />
                  <Route
                    path="/wedding-rings/:subcategory"
                    component={Productlist}
                    exact
                  />

                  <Route
                    path="/education/gemstone-education"
                    component={GemstoneEducation}
                    exact
                  />
                  <Route
                    path="/education/engagement-ring-education"
                    component={EngagementRingEducation}
                  />
                  <Route
                    path="/education/wedding-ring-education"
                    component={WeddingRingEducation}
                  />
                  <Route
                    path="/education/fine-jewelry-education"
                    component={FineJewelryEducation}
                  />
                  <Route
                    path="/education/loose-diamonds-education"
                    component={LooseDiamondEducation}
                  />
                  <Route path="/education" exact component={Education} />

                  {/* stud_builder_routes */}

                  <Route
                    path="/stud-settingdetail/:id/:slug"
                    component={Studdetails}
                  />
                  <Route
                    path="/stud-diamonds-details/:pairId"
                    component={StudSettingDiamondsDetails}
                  />
                  <Route path="/stud-settings" component={StudSettings} exact />
                  <Route
                    path="/stud-settings/:stone"
                    component={StudSettings}
                  />
                  <Route path="/stud-diamonds" component={StudDiamonds} exact />
                  <Route
                    path="/stud-diamonds/lab"
                    component={StudDiamonds}
                    exact
                  />
                  <Route
                    path="/complete-diamond-stud"
                    component={CompleteStud}
                  />
                  <Route path="*" component={NoPage} />
                  {/* stud_builder_routes */}
                </Switch>
              ) : (
                <Switch>
                  <Route path="/account/:value" component={Home1} />
                  <Route path="/invoice/:order_id" component={Home1} />
                  <Route
                    path="/engagement-rings"
                    component={RingSettings}
                    exact
                  />
                  <Route
                    path="/engagement-rings/:stone"
                    component={RingSettings}
                  />
                  <Route
                    path="/ringsettingdetail/:id/:slug"
                    component={Productdetails}
                  />
                  <Route path="/band-builder" component={BandBuilder} exact />
                  <Route path="/gemstones" component={Gemstones} exact />
                  <Route
                    path="/gemstones/:subcategory"
                    component={Gemstones}
                    exact
                  />
                  <Route
                    path="/fancy-color-diamond"
                    component={FancyColorDiamond}
                    exact
                  />
                  <Route
                    path="/fancy-color-diamond-detail/:stock_no"
                    component={Fancycolordetail}
                  />
                  <Route
                    exact
                    path="/diamonds"
                    component={RingSettingsDiamonds}
                  />
                  <Route
                    exact
                    path="/diamonds/lab/:subcategory"
                    component={RingSettingsDiamonds}
                  />
                  <Route
                    exact
                    path="/diamonds/:subcategory"
                    component={RingSettingsDiamonds}
                  />
                  <Route path="/watches" component={Watches} exact />
                  <Route path="/watches/:brand" component={Watches} exact />
                  <Route
                    path="/diamonds-details/:stock_no"
                    component={RingSettingsDiamondsDetails}
                  />
                  <Route
                    path="/watch-details/:stock_no"
                    component={WatchDetails}
                  />
                  <Route
                    path="/gemstone-details/:stock_no"
                    component={GemstonesDetails}
                  />
                  <Route
                    path="/complete-diamond-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route
                    path="/complete-gemstone-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route
                    path="/complete-fancycolor-ring"
                    component={CompleteRing}
                    exact
                  />
                  <Route path="/search/:search_text" component={Productlist} />
                  <Route path="/register" component={Register} />
                  <Route path="/cart" component={Cart} exact />
                  <Route path="/checkout-shipping" component={Checkout} exact />
                  <Route
                    path="/custom-design"
                    component={customDesigns}
                    exact
                  />
                  <Route path="/financing" component={Financing} />
                  <Route
                    path="/make-an-appointment"
                    component={MakeAnAppointment}
                  />
                  <Route path="/testimonials" component={Testimonials} />
                  <Route path="/contact" component={Contact} />
                  <Route
                    path="/returns-and-exchanges"
                    component={ReturnsExchanges}
                  />
                  <Route path="/cookies" component={Cookies} exact />
                  <Route path="/payment" component={Payment} exact />
                  <Route path="/privacy-policy" component={PrivacyPolicy} />
                  <Route
                    path="/terms-and-conditions"
                    component={TermsConditions}
                  />
                  <Route
                    path="/terms-conditions-redsys"
                    component={TermsAndConditionsRedsys}
                    exact
                  />
                  {/* <Route path="/2021-gift-guide" component={GiftGuide} /> */}
                  <Route path="/about" component={OurStory} />
                  <Route path="/blog" component={Blog} exact />
                  <Route path="/faq" component={FAQ} />
                  <Route path="/blog-details/:url" component={blogDetails} />
                  <Route path="/wishlist" component={Wishlist} />
                  <Route
                    path="/productdetail/:slug"
                    component={Productdetails}
                  />
                  <Route
                    path="/ordersuccess/:order_id/:order_no/:txn_id"
                    component={Ordersuccess}
                    exact
                  />
                  <Route path="/success" component={Ordersuccess} exact />
                  <Route
                    path="/failure"
                    component={RedsysPayementFailed}
                    exact
                  />
                  <Route
                    path="/redsyspaymentproccess"
                    component={RedsysMiddelRoute}
                    exact
                  />
                  {/* <Route
                    path="/jewelry/:listcategory/:subcategory"
                    component={Productlist}
                    exact
                  />
                  <Route
                    path="/jewelry/:listcategory"
                    component={Productlist}
                    exact
                  /> */}
                  <Route path="/fine-jewelry" component={Productlist} exact />
                  <Route path="/wedding-rings" component={Productlist} exact />
                  <Route
                    path="/fine-jewelry/:subcategory"
                    component={Productlist}
                    exact
                  />
                  <Route
                    path="/wedding-rings/:subcategory"
                    component={Productlist}
                    exact
                  />
                  <Route
                    path="/education/gemstone-education"
                    component={GemstoneEducation}
                  />
                  <Route
                    path="/education/engagement-ring-education"
                    component={EngagementRingEducation}
                  />
                  <Route
                    path="/education/wedding-ring-education"
                    component={WeddingRingEducation}
                  />
                  <Route
                    path="/education/fine-jewelry-education"
                    component={FineJewelryEducation}
                  />
                  <Route
                    path="/education/loose-diamonds-education"
                    component={LooseDiamondEducation}
                  />
                  <Route path="/education" exact component={Education} />

                  {/* stud_builder_routes */}
                  <Route
                    path="/stud-diamonds-details/:pairId"
                    component={StudSettingDiamondsDetails}
                  />
                  <Route
                    path="/complete-diamond-stud"
                    component={CompleteStud}
                    exact
                  />
                  <Route
                    path="/stud-settingdetail/:id/:slug"
                    component={Studdetails}
                  />
                  <Route path="/stud-settings" component={StudSettings} exact />
                  <Route
                    path="/stud-settings/:stone"
                    component={StudSettings}
                  />
                  <Route path="/stud-diamonds" component={StudDiamonds} exact />
                  <Route path="/stud-diamonds/lab" component={StudDiamonds} />
                  <Route
                    path="/"
                    render={() => <Redirect to="/our-story?srsltid=AfmBOopvFJc4J5XxHaJd_230nXiaTwSX1glxFmXCoJR1In6ZJTxFbtzB" />}
                    exact
                  />
                  {/* stud_builder_routes */}
                </Switch>
              )}
            </Suspense>
            <Route path="*" component={NoPage} />
          </Switch>
          <ToastContainer />
        </div>
        <Suspense
          fallback={
            <>
              <Skelton />
            </>
          }
        >
          <Footer />
          {login && !isDesktop ? <Login /> : ""}
        </Suspense>
        {/* <CookiesComponent /> */}
        <CookiesComponent1 />
      </BrowserRouter>
    </>
  );
};

// skelton component
const Skelton = () => {
  return (
    <>
      <div className="sk" style={{ margin: "15px 0px" }}>
        <Skeleton variant="text" animation="wave" />
      </div>
    </>
  );
};
// const Skeltonheader = () => {
//   return (
//     <>
//       <div className="skh" style={{ margin: "0px 0px 10px 0px" }}>
//         <Skeleton variant="text" animation="wave" height={75} />
//       </div>
//     </>
//   );
// };
export default App;
