import { useEffect } from "react";

const Redirection=()=>{
      useEffect(() => {
        if (
          window.location.pathname === "/en" ||
          window.location.pathname === "/en/" ||
          window.location.pathname === "/es" ||
          window.location.pathname === "/es/" ||
          window.location.pathname === "/en/precious-gemstone-rings/" ||
          window.location.pathname === "/en/precious-gemstone-rings" ||
          window.location.pathname === "/es/anillos-de-compromiso/" ||
          window.location.pathname === "/es/anillos-de-compromiso" ||
          window.location.pathname === "/en/jewelry-stores-estepona/" ||
          window.location.pathname === "/es/joyerias-mijas/" ||
          window.location.pathname === "/en/jewelry-stores-estepona" ||
          window.location.pathname === "/es/joyerias-mijas" ||
          window.location.pathname === "/ringsettings" ||
          window.location.pathname === "/ringsettings/" ||
          window.location.pathname === "/en/earrings-with-turquoise-diamonds/" || window.location.pathname === "/en/earrings-with-turquoise-diamonds" ||
          window.location.pathname === "/en/4-square-prongs-bracelet-with-diamonds/" || window.location.pathname === "/en/4-square-prongs-bracelet-with-diamonds" ||
          window.location.pathname === "/jewelry/fine-jewelry" || window.location.pathname === "/jewelry/fine-jewelry/" ||
          window.location.pathname === "/jewelry/wedding-rings" || window.location.pathname === "/jewelry/wedding-rings/" ||
          window.location.pathname === "/en/modern-brilliant-ring" || window.location.pathname === "/en/modern-brilliant-ring/" ||
          window.location.pathname === "/es/pendientes-solitario-de-diamante" || window.location.pathname === "/es/pendientes-solitario-de-diamante/" ||
          window.location.pathname === "/en/diamonds-and-chalcedony-earrings" || window.location.pathname === "/en/diamonds-and-chalcedony-earrings/" ||
          window.location.pathname === "/en/ruby-pendant-with-diamonds" || window.location.pathname === "/en/ruby-pendant-with-diamonds/" ||
          window.location.pathname === "/en/earrings-with-turquoise-diamonds" || window.location.pathname === "/en/earrings-with-turquoise-diamonds/" ||
          window.location.pathname === "/en/yellow-gold-ring-with-diamonds" || window.location.pathname === "/en/yellow-gold-ring-with-diamonds/" ||
          window.location.pathname === "/en/three-brilliant-cut-diamonds-ring" || window.location.pathname === "/en/three-brilliant-cut-diamonds-ring/" ||
          window.location.pathname === "/en/eternity-ring-with-9-diamonds" || window.location.pathname === "/en/eternity-ring-with-9-diamonds/" ||
          window.location.pathname === "/en/solitaire-with-brilliant-cut-diamond-ring" || window.location.pathname === "/en/solitaire-with-brilliant-cut-diamond-ring/" ||
          window.location.pathname === "/en/topaz-earrings-with-brilliant-cut-diamonds" || window.location.pathname === "/en/topaz-earrings-with-brilliant-cut-diamonds/" ||
          window.location.pathname === "/en/oval-cut-diamond-ring-with-pave-setting" || window.location.pathname === "/en/oval-cut-diamond-ring-with-pave-setting/" ||
          window.location.pathname === "/en/diamond-oval-cut-ring-with-halo" || window.location.pathname === "/en/diamond-oval-cut-ring-with-halo/" ||
          window.location.pathname === "/en/hoop-earrings-with-black-diamonds" || window.location.pathname === "/en/hoop-earrings-with-black-diamonds/" ||
          window.location.pathname === "/en/trilogy-ring-with-topaz-tourmaline" || window.location.pathname === "/en/trilogy-ring-with-topaz-tourmaline/" ||
          window.location.pathname === "/en/modern-square-shaped-gold-wedding-rings" || window.location.pathname === "/en/modern-square-shaped-gold-wedding-rings/" ||
          window.location.pathname === "/en/brilliant-halo-ring-with-accent-diamonds" || window.location.pathname === "/en/brilliant-halo-ring-with-accent-diamonds/" ||
          window.location.pathname === "/en/0-30-ct-solitaire-ring-with-diamond-6" || window.location.pathname === "/en/0-30-ct-solitaire-ring-with-diamond-6/" ||
          window.location.pathname === "/es/0-30-solitario-pedida-con-diamante-2" || window.location.pathname === "/es/0-30-solitario-pedida-con-diamante-2/" ||
          window.location.pathname === "/es/0-40-ct-central-anillo-de-compromiso-2" || window.location.pathname === "/es/0-40-ct-central-anillo-de-compromiso-2/" ||
          window.location.pathname === "/en/white-gold-pendant-with-diamonds-halo" || window.location.pathname === "/en/white-gold-pendant-with-diamonds-halo/" ||
          window.location.pathname === "/es/1-00-ct-central-oval-con-halo-diamantes" || window.location.pathname === "/es/1-00-ct-central-oval-con-halo-diamantes/" ||
          window.location.pathname === "/en/18k-gold-wedding-ring-with-diamonds" || window.location.pathname === "/en/18k-gold-wedding-ring-with-diamonds/" ||
          window.location.pathname === "/en/white-gold-ring-with-oval-cut-sapphire" || window.location.pathname === "/en/white-gold-ring-with-oval-cut-sapphire/" ||
          window.location.pathname === "/en/white-gold-engagement-ring-with-brilliants" || window.location.pathname === "/en/white-gold-engagement-ring-with-brilliants/" ||
          window.location.pathname === "/en/white-gold-padlock-pendant-with-diamonds" || window.location.pathname === "/en/white-gold-padlock-pendant-with-diamonds/" ||
          window.location.pathname === "/en/4-square-prongs-bracelet-with-42-diamonds" || window.location.pathname === "/en/4-square-prongs-bracelet-with-42-diamonds/" ||
          window.location.pathname === "/en/4-square-prongs-bracelet-with-42-diamonds" || window.location.pathname === "/en/4-square-prongs-bracelet-with-42-diamonds/" ||
          window.location.pathname === "/18-kt-white-gold-engagement-ring-with-diamond" || window.location.pathname === "/18-kt-white-gold-engagement-ring-with-diamond/" ||
          window.location.pathname === "/en/butterfly-earrings-with-diamonds-pave" || window.location.pathname === "/en/butterfly-earrings-with-diamonds-pave/" ||
          window.location.pathname === "/es/joyerias-benalmadena" || window.location.pathname === "/es/joyerias-benalmadena/" ||
          window.location.pathname === "/en/pendants" || window.location.pathname === "/en/pendants/" ||
          window.location.pathname === "/en/pink-gold-eternity-ring-with-9-diamonds" || window.location.pathname === "/en/pink-gold-eternity-ring-with-9-diamonds/" ||
          window.location.pathname === "/en/fancy-diamonds-ring" || window.location.pathname === "/en/fancy-diamonds-ring/" ||
          window.location.pathname === "/en/jewelry-stores-near-torremolinos/page/3" || window.location.pathname === "/en/jewelry-stores-near-torremolinos/page/3/" ||
          window.location.pathname === "/en/trilogy-ring-with-baguette-diamonds" || window.location.pathname === "/en/trilogy-ring-with-baguette-diamonds/" ||
          window.location.pathname === "/en/18-kt-white-gold-engagement-ring-with-diamond" || window.location.pathname === "/en/18-kt-white-gold-engagement-ring-with-diamond/" ||
          window.location.pathname === "/en/jewelry-stores-algeciras" || window.location.pathname === "/en/jewelry-stores-algeciras/" ||
          window.location.pathname === "/en/white-gold-trilogy-engagement-ring" || window.location.pathname === "/en/white-gold-trilogy-engagement-ring/" ||
          window.location.pathname === "/en/rings" || window.location.pathname === "/en/rings/" ||
          window.location.pathname === "/indian-jewelry" || window.location.pathname === "/indian-jewelry/" ||
          window.location.pathname === "/es/12-anillos-diseno" || window.location.pathname === "/es/12-anillos-diseno/" ||
          window.location.pathname === "/jewelry/pendants" || window.location.pathname === "/jewelry/pendants/" ||
          window.location.pathname === "/en/vintage-style-diamond-ring-with-halo" || window.location.pathname === "/en/vintage-style-diamond-ring-with-halo/" || 
          window.location.pathname === "/en/white-gold-pendant-with-invisible-setting-diamonds" || window.location.pathname === "/en/white-gold-pendant-with-invisible-setting-diamonds/" ||
          window.location.pathname === "/en/pink-gold-earrings-with-diamonds" || window.location.pathname === "/en/pink-gold-earrings-with-diamonds/" ||
          window.location.pathname === "/en/channel-setting-11-diamonds-eternity-ring" || window.location.pathname === "/en/channel-setting-11-diamonds-eternity-ring/" ||
          window.location.pathname === "/es/0-35-ct-central-talla-princesa-con-halo-diamantes" || window.location.pathname === "es/0-35-ct-central-talla-princesa-con-halo-diamantes/" ||
          window.location.pathname === "/es/aros-en-oro-blanco-con-brillantes" || window.location.pathname === "/es/aros-en-oro-blanco-con-brillantes/" ||
          window.location.pathname === "/es/doble-alianza-con-diamantes" || window.location.pathname === "/es/doble-alianza-con-diamantes/" ||
          window.location.pathname === "/es/solitario-clasico-con-orla-de-brillantes" || window.location.pathname === "/es/solitario-clasico-con-orla-de-brillantes/" ||
          window.location.pathname === "/es/pulsera-unisex-en-oro-amarillo-con-diamantes" || window.location.pathname === "/es/pulsera-unisex-en-oro-amarillo-con-diamantes/" ||
          window.location.pathname === "/es/alianza-oro-rosa-con-11-brillantes" || window.location.pathname === "/es/alianza-oro-rosa-con-11-brillantes/" ||
          window.location.pathname === "/en/wedding-ring-with-chain-design-and-diamonds" || window.location.pathname === "/en/wedding-ring-with-chain-design-and-diamonds/" ||
          window.location.pathname === "/es/0-22-ct-diamante-central-trilogia" || window.location.pathname === "/es/0-22-ct-diamante-central-trilogia/" ||
          window.location.pathname === "/es/anillo-con-tanzanita-y-aguamarinas" || window.location.pathname === "/es/anillo-con-tanzanita-y-aguamarinas/" ||
          window.location.pathname === "/es/0-41-ct-central-talla-marquise-con-halo-diamantes" || window.location.pathname === "/es/0-41-ct-central-talla-marquise-con-halo-diamantes/" ||
          window.location.pathname === "/es/colgante-engaste-invisible-de-diamantes" || window.location.pathname === "/es/colgante-engaste-invisible-de-diamantes/" ||
          window.location.pathname === "/wedding-rings/style=Plain%20Metal" || window.location.pathname === "/wedding-rings/style=Plain%20Metal/" ||
          window.location.pathname === "/financing" || window.location.pathname === "/financing/" ||
          window.location.pathname === "/page-not-found" || window.location.pathname === "/page-not-found/" ||
          window.location.pathname === "/en/diamond-oval-cut-ring-with-brilliants" || window.location.pathname === "/en/diamond-oval-cut-ring-with-brilliants/" ||
          window.location.pathname === "/en/sapphire-gemstones-earrings-with-diamonds" || window.location.pathname === "/en/sapphire-gemstones-earrings-with-diamonds/"
        ) {
          window.location.href = "/";
        } else if (
          window.location.pathname === "/en/engagement-rings/" ||
          window.location.pathname === "/en/engagement-rings" ||
          window.location.pathname === "/es/engagement-rings/" ||
          window.location.pathname === "/es/engagement-rings" ||
          window.location.pathname === "/en/ringsettings/" ||
          window.location.pathname === "/en/ringsettings" ||
          window.location.pathname === "/es/ringsettings/" ||
          window.location.pathname === "/es/ringsettings"
        ) {
          window.location.href = "/engagement-rings";
        } else if (
          window.location.pathname === "/en/about" ||
          window.location.pathname === "/en/about/"
        ) {
          window.location.href = "/about";
        } else if (window.location.pathname === "/en/bracelets" ||
          window.location.pathname === "/en/bracelets/") {
          window.location.href = "/fine-jewelry/bracelets"
        }
    
        else if (window.location.pathname === "/our-story" ||
          window.location.pathname === "/our-story/") {
          window.location.href = "/fine-jewelry"
        }
      }, []);

    return null
}

export default Redirection;